import React, { useState, useEffect } from "react";
import "../../stylesheets/CompetencyTemplate.css";
import Modal from "react-modal";
import "../../App.css";
import API from "../../api/api";

const ManageAreasPopup = ({ closeModal, isOpen }) => {
  const [areas, setAreas] = useState([]);
  const [newArea, setNewArea] = useState("");
  const [isAddingArea, setIsAddingArea] = useState(false);
  const [editingArea, setEditingArea] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [newGeneralTechnology, setNewGeneralTechnology] = useState(false);

  const columns = [
    {
      Header: "Areas",
      accessor: "area",
    },
  ];
  const fetchAreas = () => {
    API.areasRequest()
      .then((response) => {
        const areas = response.map((area) => ({
          area: area.name,
          general_technology: area.general_technology,
          id: area.id,
        }));
        setAreas(areas);
      })
      .catch((error) => {
        console.error("API get areas request failed: ", error);
        alert("failed to retrive areas");
      });
  };
  useEffect(() => {
    fetchAreas();
  }, []);

  const handleAddArea = () => {
    setIsAddingArea(true);
  };

  const handleSaveNewArea = () => {
    API.areasRequest({
      method: "POST",
      data: {
        cm_area: {
          name: newArea,
          general_technology: newGeneralTechnology,
        },
      },
    })
      .then(() => {
        setNewArea("");
        setIsAddingArea(false);
        setNewGeneralTechnology(false);
        fetchAreas();
      })
      .catch((error) => {
        console.error("API new areas request error: ", error);
        alert("Failed to add new area");
      });
  };

  const handleCancelNewArea = () => {
    setIsAddingArea(false);
    setNewArea("");
    setNewGeneralTechnology(false);
  };

  const handleEditArea = (area) => {
    if (editingArea === null) {
      setEditingArea(area);
      setNewArea(area.area);
      setNewGeneralTechnology(area.general_technology);
    }
  };

  const handleCancelEditArea = () => {
    setEditingArea(null);
    setNewArea("");
    setNewGeneralTechnology(false);
  };

  const handleSaveEditArea = () => {
    setIsSaving(true);
    API.areasRequest({
      method: "PATCH",
      path: {
        area_id: editingArea.id,
      },
      data: {
        cm_area: {
          name: newArea,
          general_technology: newGeneralTechnology,
        },
      },
    })
      .then(() => {
        setNewArea("");
        setEditingArea(null);
        setIsSaving(false);
        setNewGeneralTechnology(false);
        fetchAreas();
      })
      .catch((error) => {
        console.error("failed to patch area request: ", error);
        alert("Failed to edit area");
      });
  };

  return (
    <div className="modal">
      <Modal
        className="modal-edit-competency justify-content-center"
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Manage Areas Modal"
      >
        <div className="modal-content">
          <span className="close" onClick={closeModal}>
            &times;
          </span>
          <div className="modal-body">
            <h3>Manage Areas</h3>
            <p>When editing or adding, the checkbox indicates if it is a General Programming Language Area. Check this if you users to have the competencies in this area for each one of their languages.</p>
            <div className="table-container">
              <table className="language-table">
                <thead>
                  <tr>
                    {columns.map((column, index) => (
                      <th key={column.accessor}>
                        {index === 1 ? "Edit" : column.Header}
                      </th>
                    ))}
                    <th>Edit</th>
                  </tr>
                </thead>
                <tbody>
                  {areas.map((area) => (
                    <tr key={area.area}>
                      <td>
                        {editingArea === area ? (
                          <div>
                            <input
                              type="text"
                              value={newArea}
                              onChange={(e) => setNewArea(e.target.value)}
                              placeholder="Enter area"
                            />
                            <br/><br/>
                            <p>General Language: </p>
                            <input
                                className="justify-content-right align-items-right p-0"
                                type="checkbox"
                                checked={newGeneralTechnology}
                                onChange={(e) => setNewGeneralTechnology(e.target.checked)}
                              />
                          </div>
                        ) : (
                          area.area
                        )}
                      </td>
                      <td>
                        {editingArea === area ? (
                          <div>
                            <button
                              className="btn brand-button btn-sm"
                              disabled={!newArea || isSaving }
                              onClick={handleSaveEditArea}
                            >
                              Save
                            </button>
                            <button
                              className="btn brand-button btn-sm"
                              onClick={handleCancelEditArea}
                            >
                              Cancel
                            </button>
                          </div>
                        ) : (
                          <button
                            className="edit-button"
                            onClick={() => handleEditArea(area)}
                          >
                            <i className="fa fa-pencil"></i>
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {!isAddingArea ? (
              <button
                className="btn brand-button btn-sm"
                onClick={handleAddArea}
              >
                Add an Area
              </button>
            ) : (
              <div className="add-language">
                <input
                  type="text"
                  value={newArea}
                  onChange={(e) => setNewArea(e.target.value)}
                  placeholder="Enter area"
                />
                <p>General Language: </p>
                <input
                  className="justify-content-right align-items-right p-0"
                  type="checkbox"
                  checked={newGeneralTechnology}
                  onChange={(e) => setNewGeneralTechnology(e.target.checked)}
                />
                <br/><br/>
                <button
                  className="btn brand-button btn-sm"
                  disabled={!newArea || isSaving}
                  onClick={handleSaveNewArea}
                >
                  Save
                </button>
                <button
                  className="btn brand-button btn-sm"
                  onClick={handleCancelNewArea}
                >
                  Cancel
                </button>
              </div>
            )}
            <div className="modal-footer">
              <button className="btn brand-button btn-sm" onClick={closeModal}>
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default ManageAreasPopup;
