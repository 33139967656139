import React, { useState, useEffect, useContext } from "react";
import { useTable } from "react-table";
import "../../App.css";
import API from "../../api/api";
import { UserContext } from "../../contexts/UserContext";
import "../../stylesheets/ManageLanguagePopup.css";
import { SheetUserContext } from "../../contexts/SheetUserContext";

const ManageLanguagesTable = ({ onClose }) => {
  const [initialLanguages, setInitialLanguages] = useState([]);
  const [leftData, setLeftData] = useState([]);
  const [rightData, setRightData] = useState([]);
  const [savedData, setSavedData] = useState(new Map());
  const [warnings, setWarnings] = useState({
    message:
      "The following languages and its corresponding data will be deleted:",
    items: [],
  });
  const { sheetUser } = useContext(SheetUserContext);
  const [ids, setIds] = useState(new Map());
  const { user } = useContext(UserContext);
  const currentSheetId =
    sheetUser && sheetUser.sheet_id != null
      ? sheetUser.sheet_id
      : user.sheet_id;
  const currentUserId =
    sheetUser && sheetUser.sheet_user_id != null
      ? sheetUser.sheet_user_id
      : user.user_id;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [sheetTechnologies, allTechnologies] = await Promise.all([
          API.userTechnologiesRequest({
            path: {
              sheet_id: currentSheetId,
            },
            params: {
              user_id: currentUserId,
            },
          }).catch((error) => {
            console.error("API user technology request error: ", error);
            alert("Failed to retrieve user technology");
          }),
          API.technologiesRequest().catch((error) => {
            console.error("API technology request error: ", error);
            alert("Failed to retrieve all technologies");
          }),
        ]);

        const map = new Map();
        allTechnologies.forEach((item) => {
          map.set(item.name, item.id);
        });
        setIds(map);
        const leftNames = sheetTechnologies.map((item) => item.name);
        setInitialLanguages(sheetTechnologies.map((item) => item.name));
        setLeftData(leftNames);
        const rightNames = allTechnologies.map((item) => item.name);
        const filteredNames = rightNames.filter(
          (item) => !leftNames.includes(item)
        );
        setRightData(filteredNames);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [sheetUser, user, currentSheetId, currentUserId]);

  const moveItemToRight = (item) => {
    setLeftData((prevLeftData) => prevLeftData.filter((el) => el !== item));
    setRightData((prevRightData) => [...prevRightData, item]);

    const newSavedData = new Map(savedData);
    const actions = newSavedData.get(item) || [];
    actions.push("delete");
    newSavedData.set(item, actions);
    setSavedData(newSavedData);

    if (initialLanguages.includes(item)) {
      setWarnings((prevWarnings) => ({
        ...prevWarnings,
        items: [...prevWarnings.items, item],
      }));
    }
  };

  const moveItemToLeft = (item) => {
    setRightData((prevRightData) => prevRightData.filter((el) => el !== item));
    setLeftData((prevLeftData) => [...prevLeftData, item]);

    const newSavedData = new Map(savedData);
    const actions = newSavedData.get(item) || [];
    actions.push("add");
    newSavedData.set(item, actions);
    setSavedData(newSavedData);

    setWarnings((prevWarnings) => ({
      ...prevWarnings,
      items: prevWarnings.items.filter((warningItem) => warningItem !== item),
    }));
  };

  const handleSave = async () => {
    const cleanedData = removeDuplicates(savedData);
    setWarnings([]);

    for (const [item, action] of cleanedData) {
      try {
        const technology_id = ids.get(item);
        if (action === "delete") {
          const user_technology_promise = await API.userTechnologiesRequest({
            method: "GET",
            path: {
              sheet_id: currentSheetId,
            },
          })
            .then((response) => {
              return response;
            })
            .catch((error) => {
              console.error("API user technology request error", error);
              alert("Failed to retrieve user languages");
            });
          const user_technology_id = user_technology_promise.find(
            (entry) =>
              entry.cm_technology_id === technology_id &&
              entry.user_id === currentUserId
          ).id;
          await API.userTechnologiesRequest({
            method: "DELETE",
            path: {
              sheet_id: currentSheetId,
              user_technology_id: user_technology_id,
            },
          }).catch((error) => {
            console.error("API user technology error: ", error);
            alert("Failed to delete language");
          });
        } else if (action === "add") {
          await API.userTechnologiesRequest({
            method: "POST",
            path: {
              sheet_id: currentSheetId,
            },
            data: {
              cm_user_technology: {
                cm_technology_id: technology_id,
                user_id: currentUserId,
              },
            },
          }).catch((error) => {
            console.error("API failed to add new user technology", error);
            alert("failed to add new language");
          });
        }
      } catch (error) {
        console.error(`Error performing API call for item ${item}:`, error);
      }
    }
    setWarnings({
      message:
        "The following languages and its corresponding data will be deleted:",
      items: [],
    });
    onClose();
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Current Languages",
        accessor: "left",
      },
      {
        Header: "Other Languages",
        accessor: "right",
      },
    ],
    []
  );

  // Represents the languages a user currently has attached to them, we make sure to check that they can't remove all their languages (they must have at least 1)
  const leftColumnData = leftData
    .filter((item) => item !== undefined && item !== null)
    .map((item, index) => (
      <div key={`left-${index}`} className="manage-language-left-column">
        <div>{item}</div>
        {leftData.length <= 1 ? (
          <button
            className="btn brand-button btn-sm"
            onClick={() => moveItemToRight(item)}
            disabled
          >
            →
          </button>
        ) : (
          <button
            className="btn brand-button btn-sm"
            onClick={() => moveItemToRight(item)}
          >
            →
          </button>
        )}
      </div>
    ));

  const rightColumnData = rightData
    .filter((item) => item !== undefined && item !== null)
    .map((item, index) => (
      <div key={`left-${index}`}>
        <button
          className="btn brand-button btn-sm"
          onClick={() => moveItemToLeft(item)}
        >
          ←
        </button>
        &nbsp;
        {item}
      </div>
    ));

  const data = React.useMemo(
    () => [
      {
        left: leftColumnData,
        right: rightColumnData,
      },
    ],
    [leftColumnData, rightColumnData]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <div>
      <div className="manage-languages-warnings">
        {warnings.items && warnings.items.length > 0 ? (
          <div className="manage-languages-warnings">
            {warnings.message}
            <br />
            <strong>{warnings.items.join(", ")}</strong>
          </div>
        ) : null}
      </div>
      <table {...getTableProps()} style={{ width: "400px", margin: "auto" }}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <br />
      <div className="manage-languages-save-btn">
        <button className="btn brand-button" onClick={handleSave}>
          Save & Close
        </button>
      </div>
    </div>
  );
};

export default ManageLanguagesTable;

function removeDuplicates(map) {
  const result = new Map();

  for (const [item, actions] of map.entries()) {
    const uniqueActions = removePairs(actions);
    if (uniqueActions.length > 0) {
      result.set(item, uniqueActions);
    }
  }

  return result;
}

function removePairs(actions) {
  let addCount = 0;
  let deleteCount = 0;

  for (const action of actions) {
    if (action === "add") {
      addCount++;
    } else if (action === "delete") {
      deleteCount++;
    }
  }

  if (addCount > deleteCount) {
    return "add";
  } else if (deleteCount > addCount) {
    return "delete";
  }

  return [];
}
