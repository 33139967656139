import React, { useContext } from "react";
import logo from "../images/logo.png";
import { Link } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";

export const Header = () => {
  const { user } = useContext(UserContext);
  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("cm_token");
    document.cookie = `transition_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${process.env.REACT_APP_CM_COOKIE_DOMAIN}`;
    window.location.href = window.location.origin;
  };
  
  return (
    <nav className="navbar navbar-dark navbar-expand-lg fixed-top bg-white portfolio-navbar gradient">
  <div className="container">
    <Link className="navbar-brand logo" to="/sheet">
      <img src={logo} alt="Solution Street logo" className="img-fluid" />
    </Link>

    <div className="ml-auto">
      {(user.is_admin || user.is_manager) && window.location.pathname !== "/" && (
        <Link to="/competency-matrices" className="btn btn-link text-dark text-decoration-none p-3">
          Competency Matrices
        </Link>
      )}
      {(user.is_admin || user.is_manager) && window.location.pathname !== "/" && (
        <Link to="/competency-matrix-template" className="btn btn-link text-dark text-decoration-none p-3">
          Competency Templates
        </Link>
      )}
      <Link to={process.env.REACT_APP_TIMESHEET_URL} className="btn btn-link text-dark text-decoration-none p-3">
        Timesheets
      </Link>
      {user.user_id != null && (
        <button
          type="button"
          className="btn bg-transparent border-0 text-dark"
          onClick={handleLogout}
        >
          Log out
        </button>
      )}
    </div>
  </div>
</nav>
  );
};
