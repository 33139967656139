import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { Link} from 'react-router-dom';
import { useTable, useSortBy, useFilters } from "react-table";
import API from "../api/api";
import { COLUMNS } from "../constants/CompetenciesTemplateColumn";
import { useContext } from "react";
import { UserContext } from "../contexts/UserContext";
import ManageRolesPopup from "../components/modal/ManageRolesPopup";
import AdminManageLanguagePopup from "../components/modal/AdminManageLanguagesPopup";
import ManageAreasPopup from "../components/modal/ManageAreasPopup";
import "../App.css";

const CompetenciesTemplate = () => {
  const columns = useMemo(() => COLUMNS, []);
  const [data, setData] = useState([]);
  const [notice, setNotice] = useState(null);
  const { user } = useContext(UserContext);
  const date_column_id = "updated_at";
  const competency_name_column_id = "name";
  const [showManageRolesPopup, setShowManageRolesPopup] = useState(false);
  const [showLanguagesPopup, setShowLanguagesPopup] = useState(false);
  const [showAreasPopup, setShowAreasPopup] = useState(false);
  const [filterRole, setFilterRole] = useState("");
  const [rolesMap, setRolesMap] = useState({});

  const defaultColumn = useMemo(
    () => ({
      Filter: () => null,
    }),
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        sortBy: [{ id: "area_name", desc: false }],
      },
      autoResetFilters: false,
    },
    useFilters,
    useSortBy
  );

  const handleManageRolesClick = () => {
    setShowManageRolesPopup(true);
  };

  const handleManageAreasClick = () => {
    setShowAreasPopup(true);
  };

  const handleManageLanguagesClick = () => {
    setShowLanguagesPopup(true);
  };

  const handleRoleFilterChange = (currentRole) => {
    setFilterRole(currentRole);
  };

  useEffect(() =>{
    const passedNotice = localStorage.getItem('notification');
    if(passedNotice){
      setNotice(localStorage.getItem('notification'));
      localStorage.removeItem('notification')
    }
  },[]);

  useEffect(() => {
    API.rolesRequest().then((response) => {
      let newRolesMap = {};
      response.forEach((role) => { newRolesMap[role.name] = role.id; });
      setRolesMap(newRolesMap);
    });
  }, []);

  useEffect(() => {
    API.competenciesRequest()
      .then((responseCompetencies) => {
        API.areasRequest()
          .then((areas) => {
            Promise.all(
              responseCompetencies.map((competency) => {
                const correspondingArea = areas.find(
                  (area) => area.id === competency.cm_area_id
                );
                if (!correspondingArea) {
                  return null;
                }
                return API.rolesRequest({
                  path: {
                    role_id: competency.cm_role_id,
                  },
                })
                  .then((role) => {
                    return {
                      area_id: competency.cm_area_id,
                      role_id: competency.cm_role_id,
                      description: competency.description,
                      no_experience: competency.no_experience,
                      basic: competency.basic,
                      intermediate: competency.intermediate,
                      advanced: competency.advanced,
                      name: competency.name,
                      area_name: correspondingArea.name,
                      general_technology: correspondingArea.general_technology,
                      updated_at: competency.updated_at,
                      role_name: role.name,
                      competency_id: competency.id,
                      published:
                        competency.published === null ||
                        competency.published === false
                          ? "Un-Published"
                          : "Published",
                    };
                  })
                  .catch((error) => {
                    console.error("roles request error: ", error);
                    alert("failed to retrieve all roles");
                  });
              })
            ).then((newData) => {
              const filteredData = newData.filter((item) => item !== null);
              const isRolesColumnSorted = tableInstance.state.sortBy.some(
                (sortItem) => sortItem.id === "role_name"
              );
              const isLastUpdatedColumnSorted = tableInstance.state.sortBy.some(
                (sortItem) => sortItem.id === "updated_at"
              );

              if (!isRolesColumnSorted && !isLastUpdatedColumnSorted) {
                filteredData.sort((a, b) =>
                  a.area_name.localeCompare(b.area_name)
                );
              }
              
              if (filterRole) {
                const roleFilteredData = filteredData.filter(
                  (row) => row.role_id == filterRole
                );
                setData(roleFilteredData);
              } else {
                setData(filteredData);
              }
            });
          })
          .catch((error) => {
            console.error("area request error: ", error);
            alert("Failed to retrieve all areas");
          });
      })
      .catch((error) => {
        console.error("competency request error: ", error);
        alert("Failed to retrieve all competencies");
      });
  }, [user.sheet_id, user.user_id, filterRole]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <div className="container">
      <h1 className="text-center">Competency Matrix Template</h1>
      <div className="text-center">
        <div>
          {(user.is_admin || user.is_manager) && (
            <Link to='/competency'>
              <button className="btn brand-button"> Add New Competency </button>
            </Link>
          )}

          {user.is_admin && (
            <>
              <button
                className="btn brand-button"
                onClick={handleManageLanguagesClick}
              >
                Manage All Languages
              </button>
              <button
                className="btn brand-button"
                onClick={handleManageRolesClick}
              >
                Manage Disciplines
              </button>
              <button
                className="btn brand-button"
                onClick={handleManageAreasClick}
              >
                Manage Areas
              </button>
            </>
          )}
        </div>
        <br />
        <br />
        <p><b>{ notice }</b></p>
        <div className="filter-container">
          <p className="filter-by-discipline">Filter by Discipline:</p>
          <select
            className="pt-0 pl-3"
            value={filterRole}
            onChange={(e) => handleRoleFilterChange(e.target.value)}
          >
            <option value="">All</option>
            {Object.entries(rolesMap).map(([role, value]) => (
              <option key={value} value={value}>
                {role}
               </option>
            ))}
          </select>
        </div>
      </div>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  className="th-sticky"
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render("Header")}
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                  <span>
                    {column.isSorted && column.id !== "area_name"
                      ? column.isSortedDesc
                        ? " ↓"
                        : " ↑"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  if (cell.column.id === date_column_id) {
                    return (
                      <td {...cell.getCellProps()}>{formatDate(cell.value)}</td>
                    );
                  } else if (cell.column.id === competency_name_column_id) {
                    return (
                      <td
                        {...cell.getCellProps()}
                      >
                        <Link to={`/competency/${row.original.competency_id}`}>
                          {row.original.name}
                        </Link>

                      </td>
                    );
                  } else {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {showLanguagesPopup && (
        <AdminManageLanguagePopup
          closeModal={() => setShowLanguagesPopup(false)}
          isOpen={showLanguagesPopup}
        ></AdminManageLanguagePopup>
      )}
      {showManageRolesPopup && (
        <ManageRolesPopup
          closeModal={() => setShowManageRolesPopup(false)}
          isOpen={showManageRolesPopup}
        ></ManageRolesPopup>
      )}
      {showAreasPopup && (
        <ManageAreasPopup
          closeModal={() => setShowAreasPopup(false)}
          isOpen={showAreasPopup}
        ></ManageAreasPopup>
      )}
    </div>
  );
};

function formatDate(dateString) {
  const options = {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  const date = new Date(dateString);
  return date.toLocaleString("en-US", options);
}

export default CompetenciesTemplate;
