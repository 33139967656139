export const NO_EXPERIENCE = "no_experience";
export const LEVEL_NONE = "None";
export const LEVEL_BASIC = "Basic";
export const LEVEL_INTERMEDIATE = "Intermediate";
export const LEVEL_ADVANCED = "Advanced"
export const LEVELS_ORDER = { 'no_experience': 0, 'basic': 1, 'intermediate': 2, 'advanced': 3 };
export const COMPETENCY_LEVELS = [
  LEVEL_NONE,
  LEVEL_BASIC,
  LEVEL_INTERMEDIATE,
  LEVEL_ADVANCED,
];
