import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../App.css";
import CriteriaPopup from "../components/modal/CriteriaPopup";

export const FilterComponent = ({ column }) => {
  const { filterValue, setFilter } = column;

  useEffect(() => { //inital rendering check if there was a previous filter
    const savedFilter = localStorage.getItem(`${column.id}_filter`);
    if (savedFilter) {
      setFilter(savedFilter);
    }
  }, []);

  useEffect(() => { //save filter in local storage
    filterValue ? localStorage.setItem(`${column.id}_filter`, filterValue) : localStorage.removeItem(`${column.id}_filter`)
  }, [filterValue]);

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => setFilter(e.target.value)}
      placeholder="Search..."
    />
  );
};

export const COLUMNS = [
  {
    Header: "Area",
    accessor: "area_name",
    Filter: FilterComponent,
    disableSortBy: true,
  },
  {
    Header: "Competency",
    accessor: "name",
    disableSortBy: true,
    Filter: FilterComponent,
  },
  {
    Header: "Description",
    accessor: "description",
    disableSortBy: true,
  },
  {
    Header: "Disciplines",
    accessor: "role_name",
  },
  {
    Header: "Learning Pointers",
    accessor: "learning_pointer",
    Cell: ({ row }) => <PopupButton data={row.original} />,
    disableSortBy: true,
  },
  {
    Header: "Criteria",
    accessor: "criteria",
    Cell: ({ row }) => <CriteriaPopup data={row.original} />,
    disableSortBy: true,
  },
  {
    Header: "Last Updated",
    accessor: "updated_at",
  },
  {
    Header: "Published",
    accessor: "published"
  }
];

const PopupButton = ({ data }) => {
  const navigate = useNavigate();

  const learningPointerClick = async () => {
    const { competency_id } = data;
    navigate(`/learning-pointers?competency_id=${competency_id}`);
  };

  return (
    <div className="d-flex justify-content-center">
      <button
        type="button"
        className="btn brand-button btn-sm"
        onClick={learningPointerClick}
      >
        View
      </button>
    </div>
  );
};
