import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import Modal from "react-modal";
import "../../stylesheets/LanguageSelectPopup.css";
import API from "../../api/api";
import { UserContext } from "../../contexts/UserContext";
import { SheetUserContext } from "../../contexts/SheetUserContext";

const LanguageSelectPopup = () => {
  const [isModalOpen, setModalOpen] = useState(true);
  const [options, setOptions] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const { user } = useContext(UserContext);
  const { sheetUser } = useContext(SheetUserContext);
  const currentSheetId =
  sheetUser && sheetUser.sheet_id != null
    ? sheetUser.sheet_id
    : user.sheet_id;
const currentUserId =
  sheetUser && sheetUser.sheet_user_id != null
    ? sheetUser.sheet_user_id
    : user.user_id;

    useEffect(() => {
      if (isModalOpen) {
        document.body.classList.add("disable-scroll");
      } else {
        document.body.classList.remove("disable-scroll");
      }
    }, [isModalOpen]);
  
    const handleModalClose = () => {
      setModalOpen(false);
    };

  useEffect(() => {
    API.technologiesRequest()
      .then((response) => {
        const newOptions = response.map((technology) => ({
          value: technology.id,
          label: technology.name,
        }));
        setOptions(newOptions);
      })
      .catch((error) => {
        console.error("APi technology request error: ", error);
        alert("Failed to retrieve technologies");
      });
  }, []);

  const handleChange = (selectedOptions) => {
    setSelectedLanguages(selectedOptions);
  };

  return (
    <div>
      <Modal
        className="modal-language-select"
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        contentLabel="LanguageSelect Modal"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
      >
        <h2 className="language-select-popup-title">Language Select</h2>
        <Select
          className="language-select-dropdown"
          options={options}
          isMulti
          onChange={handleChange}
          value={selectedLanguages}
          placeholder="Select Language..."
        />
        <div className="language-select-btns">
          <button
            className="language-select-btn"
            onClick={() => {
              handleModalClose();
              selectedLanguages.forEach((language) => {
                API.userTechnologiesRequest({
                  method: "POST",
                  path: {
                    sheet_id: currentSheetId
                  },
                  data: {
                    cm_user_technology: {
                      cm_technology_id: language.value,
                      user_id: currentUserId,
                    },
                  },
                }).catch((error) => {
                  console.error(
                    "API user technology post request failed: ",
                    error
                  );
                  alert("Failed to have new user language");
                });
              });
            }}
            disabled={!selectedLanguages.length}
          >
            Save
          </button>

          <p className="language-select-caption">
            Please select at least one language to continue.
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default LanguageSelectPopup;
