function customLinkHandler(value) {
  if (value) {
    const attributes = { href: value, target: "_blank", rel: "noopener noreferrer" };
    this.quill.theme.tooltip.edit("link", attributes);
  } else {
    this.quill.format("link", false);
  }
}

export const quillModules = {
    toolbar: [
      [{ font: [] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ link: customLinkHandler }],
      ["clean"],
    ],
  };
  
export const quillFormats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "script",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];