import React from "react";
import NotesPopup from "../components/modal/NotesPopup";
import LevelButton from "../components/LevelButton";
import { useNavigate } from "react-router-dom";
import "../App.css";
import CriteriaPopup from "../components/modal/CriteriaPopup";
import { LEVELS_ORDER } from "./Levels";

export const FilterComponent = ({ column }) => {
  const { filterValue, setFilter } = column;

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => setFilter(e.target.value)}
      placeholder="Search..."
    />
  );
};

export const getColumns = (filterRole) => [
  {
    Header: "Area",
    accessor: "area_name",
    Filter: FilterComponent,
    disableSortBy: true,
  },
  {
    Header: "Competency",
    accessor: "competency.name",
    disableSortBy: true,
    Filter: FilterComponent,
  },
  {
    Header: "Description",
    accessor: "competency.description",
    disableSortBy: true,
  },
  {
    Header: "Level",
    accessor: "level",
    Cell: ({ row }) =>{
      if (filterRole && filterRole.value && row.original.competency.cm_role_id !== filterRole.value) {
        return null;
      }
     return <LevelButton data={row.original} />
    },
    sortType: (rowA, rowB) => {
      let levelOrderA;
      let levelOrderB;

      if (rowA.original.competency_change) {
        levelOrderA = LEVELS_ORDER[rowA.original.competency_change.current_level.toLowerCase()];
      } else { levelOrderA = 0; }
      if (rowB.original.competency_change) {
        levelOrderB = LEVELS_ORDER[rowB.original.competency_change.current_level.toLowerCase()];
      } else { levelOrderB = 0; }

      // Sorting
      return levelOrderA - levelOrderB;
    },
  },
  {
    Header: "Learning Pointers",
    accessor: "learning_pointer",
    Cell: ({ row }) => <PopupButton data={row.original.competency.id} />,
    disableSortBy: true,
  },
  {
    Header: "Criteria",
    accessor: "criteria",
    Cell: ({ row }) => <CriteriaPopup data={row.original.competency} />,
    disableSortBy: true,
  },
  {
    Header: "Notes",
    accessor: "sheet_competency.notes",
    Cell: ({ row }) => <NotesPopup data={row.original} />,
    disableSortBy: true,
  },
  {
    Header: "Last Updated",
    accessor: "competency_change.updated_at",
  },
];

const PopupButton = ({ data }) => {
  const navigate = useNavigate();

  const learningPointerClick = async () => {
    const competency_id  = data;
    navigate(`/learning-pointers?competency_id=${competency_id}`);
  };

  return (
    <div className="d-flex justify-content-center">
      <button
        type="button"
        className="btn brand-button btn-sm"
        onClick={learningPointerClick}
      >
        View
      </button>
    </div>
  );
};
