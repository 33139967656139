import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import Modal from "react-modal";
import "../../stylesheets/NewLearningPointerPopup.css";
import API from "../../api/api";
import { useSearchParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { quillModules, quillFormats } from "../QuillEssentials";
import "../../stylesheets/wysiwyg.css";

const EditLearningPointerPopup = ({
  isOpen,
  onClose,
  learningPointer,
  onSaveLearningPointer,
}) => {
  const [searchParams] = useSearchParams();
  const competency_id = searchParams.get("competency_id");
  const [, setModalOpen] = useState(true);
  const [name, setName] = useState("");
  const [notes, setNotes] = useState("");
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [selectedCompetencies, setSelectedCompetencies] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);
  const [competencyOptions, setCompetencyOptions] = useState([]);
  const [areaCompetencies, setAreaCompetencies] = useState({});
  const [competencyLearningPointers, setCompetencyLearningPointers] = useState(
    []
  );

  const handleModalClose = () => {
    document.body.classList.remove("disable-scroll");
    setModalOpen(false);
    onClose();
  };

  const fetchData = useCallback(async () => {
    try {
      const [
        areasResponse,
        competenciesResponse,
        competencyLearningPointersResponse,
      ] = await Promise.all([
        API.areasRequest(),
        API.competenciesRequest(),
        API.competencyLearningPointersRequest(),
      ]);

      const newOptions = areasResponse.map((area) => ({
        value: area.id,
        label: area.name,
      }));
      setAreaOptions(newOptions);

      const names = new Map();
      const areaIds = new Map();

      competenciesResponse.forEach((competency) => {
        names.set(competency.id, competency.name);
        areaIds.set(competency.id, competency.cm_area_id);
      });

      // Get competencies associated with the current learning pointer
      const linkedCompetencies = competencyLearningPointersResponse.filter(
        (entry) => entry.cm_learning_pointer_id === learningPointer.id
      );

      const linkedCompetencyIds = linkedCompetencies.map(
        (entry) => entry.cm_competency_id
      );

      const selectedCompetenciesArray = linkedCompetencyIds.map((id) => ({
        value: id,
        label: names.get(id),
        area: areaIds.get(id),
      }));

      const linkedAreaIds = [
        ...new Set(selectedCompetenciesArray.map((item) => item.area)),
      ];

      const selectedAreasArray = linkedAreaIds.map((id) => ({
        value: id,
        label: newOptions.find((option) => option.value === id).label,
      }));

      setSelectedCompetencies(selectedCompetenciesArray);
      setSelectedAreas(selectedAreasArray);

      setName(learningPointer.name);
      setNotes(learningPointer.description);
      setCompetencyLearningPointers(competencyLearningPointersResponse);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [competency_id, learningPointer]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (selectedAreas.length > 0) {
      const fetchCompetenciesByArea = async (area) => {
        try {
          const response = await API.competenciesRequest(area.value);
          const competencies = response
            .filter((competency) => competency.cm_area_id === area.value)
            .map((competency) => ({
              value: competency.id,
              label: competency.name,
              area: area.value,
            }));
          setAreaCompetencies((prevState) => ({
            ...prevState,
            [area.value]: competencies,
          }));
        } catch (error) {
          console.error("Error fetching competencies:", error);
        }
      };

      selectedAreas.forEach(fetchCompetenciesByArea);
    }
  }, [selectedAreas]);

  useEffect(() => {
    const newCompetencyOptions = Object.values(areaCompetencies).flat();
    setCompetencyOptions(newCompetencyOptions);
  }, [areaCompetencies]);

  const handleAreaChange = (selected) => {
    setSelectedAreas(selected);

    const newSelectedCompetencies = selectedCompetencies.filter((competency) =>
      selected.some((area) => area.value === competency.area)
    );

    setSelectedCompetencies(newSelectedCompetencies);
  };

  const saveLearningPointer = () => {
    API.learningPointersRequest({
      method: "PATCH",
      path: {
        learning_pointer_id: learningPointer.id,
      },
      data: {
        cm_learning_pointer: {
          name: name,
          description: notes,
        },
      },
    }).then((callback) => {
      const originalCompetencyIds = competencyLearningPointers.filter(
        (entry) => entry.cm_learning_pointer_id === learningPointer.id
      );

      const selectedCompetencyIds = selectedCompetencies.map(
        (competency) => competency.value
      );

      const selectedCompetencyIdsNumber = selectedCompetencyIds.map(Number);

      const originalCompetencyIdsValues = originalCompetencyIds.map(
        (entry) => entry.cm_competency_id
      );

      const removeCompetencyIds = originalCompetencyIds.filter(
        (entry) => !selectedCompetencyIdsNumber.includes(entry.cm_competency_id)
      );

      const newCompetencyIds = selectedCompetencyIdsNumber.filter(
        (id) => !originalCompetencyIdsValues.includes(id)
      );

      newCompetencyIds.forEach((competencyId) => {
        API.competencyLearningPointersRequest({
          method: "POST",
          data: {
            cm_competency_learning_pointer: {
              cm_competency_id: competencyId,
              cm_learning_pointer_id: callback.id,
            },
          },
        });
      });

      removeCompetencyIds.forEach((competency) => {
        API.competencyLearningPointersRequest({
          method: "DELETE",
          path: {
            competency_learning_pointer_id: competency.id,
          },
        });
      });
    });
  };

  return (
    <div>
      <Modal
        className="modal-new-learning-pointer"
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel="newLearningPointer Modal"
      >
        <h2 className="new-learning-pointer-popup-title">
          Edit Learning Pointer
        </h2>
        <label className="new-learning-pointer-popup-label">Name</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <label className="new-learning-pointer-popup-label">Notes</label>
        <ReactQuill
          className="quill-editor"
          value={notes}
          onChange={setNotes}
          modules={quillModules}
          formats={quillFormats}
        />
        <fieldset className="new-learning-pointer-field-set">
          <legend className="new-learning-pointer-field-set-legend">
            Tags
          </legend>
          <Select
            className="new-learning-pointer-dropdown"
            options={areaOptions}
            isMulti={true}
            onChange={handleAreaChange}
            value={selectedAreas}
            placeholder="Select Area..."
          />
          {selectedAreas.length > 0 && (
            <Select
              className="new-learning-pointer-dropdown"
              options={competencyOptions}
              isMulti={true}
              onChange={setSelectedCompetencies}
              value={selectedCompetencies}
              placeholder="Select Competency..."
            />
          )}
        </fieldset>
        <div className="new-learning-pointer-btn-container">
          <button
            className="new-learning-pointer-cancel-btn"
            onClick={handleModalClose}
          >
            Cancel
          </button>
          <button
            className="new-learning-pointer-save-btn"
            onClick={() => {
              saveLearningPointer();
              handleModalClose();
            }}
            disabled={!(name && notes) || selectedCompetencies.length === 0}
          >
            Save
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default EditLearningPointerPopup;
