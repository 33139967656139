import * as React from "react";
import { useMemo } from "react";
import { useTable, useSortBy, useFilters } from "react-table";
import API from "../api/api";
import { useContext, useState, useEffect } from "react";
import { UserContext } from "../contexts/UserContext";
import { Link } from "react-router-dom";
import { SheetUserContext } from "../contexts/SheetUserContext";

const ManagerTable = () => {
  const { user } = useContext(UserContext);
  const { setSheetUserViaResponse } = useContext(SheetUserContext);
  const columns = useMemo(
    () => getColumns(setSheetUserViaResponse, user.user_id),
    [setSheetUserViaResponse, user.user_id]
  );
  const [data, setData] = useState([]);
  const [filterManagerInput, setFilterManagerInput] = useState("");
  const [filterEmployeeInput, setFilterEmployeeInput] = useState("");

  const handleEmployeeFilterChange = (e) => {
    const value = e.target.value || undefined;
    setFilter("employee", value);
    setFilterEmployeeInput(value);
  };
  const handleManagerFilterChange = (e) => {
    const value = e.target.value || undefined;
    setFilter("manager", value);
    setFilterManagerInput(value);
  };

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useSortBy
  );

  useEffect(() => {
    API.employeeManagerRequest({ params: { manager_id: user.user_id } })
      .then((responseEmployees) => {
        const newData = responseEmployees.employees
          .map((employee) => {
            return {
              employee: `${employee.first_name} ${employee.last_name}`,
              employee_id: employee.id,
              sheet: employee.sheet,
              manager: employee.manager,
              roles: employee.roles
                .map((role) => {
                  if (role !== null) {
                    return role.name;
                  }
                })
                .join(", "),
              last_updated: employee.sheet
                ? formatDate(employee.sheet.updated_at)
                : "N/A",
              notifications: employee.sheet_notifications
                ? employee.sheet_notifications
                    .map((noti) => {
                      return `${noti}`;
                    })
                    .join("\n")
                : "",
            };
          })
          .sort((employee1, employee2) => {
            return (
              employee2.notifications.length - employee1.notifications.length
            );
          });
        setData(newData);
      })
      .catch((error) => {
        console.error("API employee manager request error: ", error);
        alert("Fails to get employee manager information");
      });
  }, [user.user_id]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
  } = tableInstance;
  return (
    <div>
      <input
        value={filterManagerInput}
        onChange={handleManagerFilterChange}
        placeholder={"Search Managers"}
      />
      <input
        value={filterEmployeeInput}
        onChange={handleEmployeeFilterChange}
        placeholder={"Search Employees"}
      />
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  className="th-sticky"
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? " ↓" : " ↑") : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export const FilterComponent = ({ column }) => {
  const { filterValue, setFilter } = column;

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => setFilter(e.target.value)}
      placeholder="Search..."
    />
  );
};

const getColumns = (setSheetUserViaResponse, loggedInUserID) => [
  {
    Header: "Manager",
    accessor: "manager",
    Filter: FilterComponent,
    Cell: ({ cell }) => (
      <>
        <React.Fragment>
          <strong>
            <em>{cell.value}</em>
          </strong>
          <br />
        </React.Fragment>
      </>
    ),
  },
  {
    Header: "Employee",
    accessor: "employee",
    Filter: FilterComponent,
    Cell: ({ cell }) => {
      const isOwnSheet =
        cell.row.original.sheet &&
        cell.row.original.sheet.user_id === loggedInUserID;
      return cell.row.original.sheet ? (
        <Link
          to={
            isOwnSheet
              ? "/sheet"
              : `/users/${cell.row.original.sheet.user_id}/sheets/${cell.row.original.sheet.id}`
          }
          onClick={() => {
            if (!isOwnSheet) {
              API.usersRequest({
                path: { user_id: cell.row.original.sheet.user_id },
              }).catch((error) => {
                console.error("API employee manager request error: ", error);
                alert("Fails to get employee users");
              });
            }
          }}
        >
          {cell.value}{" "}
        </Link>
      ) : (
        cell.value
      );
    },
  },
  {
    Header: "Disciplines",
    accessor: "roles",
    Cell: ({ cell }) => cell.row.original.roles,
  },
  {
    Header: "Notifications",
    accessor: "notifications",
    Cell: ({ cell }) => (
      <>
        {cell.value.split("\n").map((noti, i) => (
          <React.Fragment key={i}>
            <strong>{noti}</strong>
            <br />
          </React.Fragment>
        ))}
      </>
    ),
  },
  {
    Header: "Last Updated",
    accessor: "last_updated",
  },
];

function formatDate(dateString) {
  if (dateString == null) {
    return "No Updates";
  }
  const options = {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  const date = new Date(dateString);
  return date.toLocaleString("en-US", options);
}

export default ManagerTable;
