import React, { useState, useEffect, useContext } from "react";
import "../stylesheets/LevelButton.css";
import API from "../api/api";
import * as LEVELS from "../constants/Levels";
import { UserContext } from "../contexts/UserContext";

const LevelButton = ({ data }) => {
  const { user } = useContext(UserContext);
  const { sheetUser } = useContext(UserContext);

  const initialSelectedValue = data.competency_change == null || data.competency_change.current_level === LEVELS.NO_EXPERIENCE ? LEVELS.LEVEL_NONE : data.competency_change.current_level;
  const initialDesiredValue = data.competency_change == null || data.competency_change.desired_level === LEVELS.NO_EXPERIENCE ? LEVELS.LEVEL_NONE : data.competency_change.desired_level; 
  const [selectedValue, setSelectedValue] = useState(initialSelectedValue);
  const [desiredValue, setDesiredValue] = useState(initialDesiredValue);
  const [isInitialRender, setIsInitialRender] = useState(true);

  const current_level = selectedValue.toLowerCase() === LEVELS.LEVEL_NONE.toLowerCase() ? LEVELS.NO_EXPERIENCE : selectedValue.toLowerCase();
  const desired_level = desiredValue.toLowerCase() === LEVELS.LEVEL_NONE.toLowerCase() ? LEVELS.NO_EXPERIENCE : desiredValue.toLowerCase();

  const isOwnAdminSheet = () => {
    return data.sheet_competency.cm_sheet_id !== user.sheet_id && (user.is_admin || user.is_manager);
  };

  const handleChange = (event) => {
    if (isOwnAdminSheet()) {
      setSelectedValue(event.target.value);
      setDesiredValue(event.target.value);
    }
    setDesiredValue(event.target.value);
  };

  const handleApprove = () => {
    API.managerCompetencyChangeRequest({
      method: "POST",
      path: {
        sheet_id: data.sheet_competency.cm_sheet_id,
        sheet_competency_id: data.sheet_competency.id,
        competency_change_id: data.competency_change.id,
      },
      data: {
        cm_manager_competency_change: {
          approved_level: desired_level,
          cm_competency_change_id: data.competency_change.id,
        },
      },
    })
      .then((response) => {
        if (response) {
          API.competencyChangeRequest({
            method: "POST",
            path: {
              sheet_id: data.sheet_competency.cm_sheet_id,
              sheet_competency_id: data.sheet_competency.id,
            },
            data: {
              cm_competency_change: {
                current_level: desired_level,
                desired_level: desired_level,
                cm_sheet_competencies_id: data.sheet_competency.id,
              },
            },
          })
            .then(() => {
              setSelectedValue(desired_level);
              setDesiredValue(desired_level);
            })
            .catch((error) => {
              console.error("API LevelButton request error: ", error);
              alert("Fails to save competency changes");
            });
        }
      })
      .catch((error) => {
        console.error("API LevelButton request error: ", error);
        alert("Fails to save manager competency changes information");
      });
  };

  const handleClick = (event) => {
    if (
      event.target.value &&
      event.target.value.toLowerCase() === selectedValue.toLowerCase()
    ) {
      setDesiredValue(event.target.value.toLowerCase());
    }
  };

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
    } else if (isOwnAdminSheet()) {
      API.competencyChangeRequest({
        method: "POST",
        path: {
          sheet_id: data.sheet_competency.cm_sheet_id,
          sheet_competency_id: data.sheet_competency.id,
        },
        data: {
          cm_competency_change: {
            current_level: current_level,
            desired_level: current_level,
            cm_sheet_competencies_id: data.sheet_competency.id,
          },
        },
      }).catch((error) => {
        console.error("API LevelButton request error: ", error);
        alert("Fails to save competency changes");
      });
    } else {
      API.competencyChangeRequest({
        method: "POST",
        path: {
          sheet_id: data.sheet_competency.cm_sheet_id,
          sheet_competency_id: data.sheet_competency.id,
        },
        data: {
          cm_competency_change: {
            current_level: current_level,
            desired_level: desired_level,
            cm_sheet_competencies_id: data.sheet_competency.id,
          },
        },
      }).catch((error) => {
        console.error("API competencty change post request error: ", error);
        alert("Unable to save competency change");
      });
    }
  }, [desiredValue]);

  return (
    <div className="level-button-container">
      {LEVELS.COMPETENCY_LEVELS.map((level) => (
        <div
          key={level}
          className={`level-button-inner-container ${
            selectedValue.toLowerCase() === level.toLowerCase()
              ? "grey-background"
              : desiredValue.toLowerCase() === level.toLowerCase()
              ? "desired-level"
              : ""
          }`}
          onClick={handleClick}
        >
          <span>{level}</span>
          {selectedValue.toLowerCase() !== desiredValue.toLowerCase() &&
          desiredValue.toLowerCase() === level.toLowerCase() ? (
            <>
              <span className="level-button-pending-text">PENDING</span>
              {isOwnAdminSheet() && sheetUser !== null && (
                <div className="level-button-pending">
                  <button onClick={handleApprove}>&#10004;</button>
                </div>
              )}
            </>
          ) : (
            <input
              type="radio"
              name={`level-${data.sheet_competency.id}`}
              value={level}
              checked={selectedValue.toLowerCase() === level.toLowerCase()}
              onChange={handleChange}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default LevelButton;
