import React, { useState, useEffect } from "react";
import "../../stylesheets/CompetencyTemplate.css";
import Modal from "react-modal";
import "../../App.css";
import API from "../../api/api";

const AdminManageLanguagePopup = ({ closeModal, isOpen }) => {
  const [languages, setLanguages] = useState([]);
  const [newLanguage, setNewLanguage] = useState("");
  const [isAddingLanguage, setIsAddingLanguage] = useState(false);
  const [editingLanguage, setEditingLanguage] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const columns = [
    {
      Header: "Languages",
      accessor: "language",
    },
  ];

  const fetchLanguages = () => {
    API.technologiesRequest()
      .then((response) => {
        const languages = response.map((language) => ({
          language: language.name,
          id: language.id,
        }));
        setLanguages(languages);
      })
      .catch((error) => {
        console.error("API technology reqest failed: ", error);
        alert("Failed to retrieve all technologies");
      });
  };

  useEffect(() => {
    fetchLanguages();
  }, []);

  const handleAddLanguage = () => {
    setIsAddingLanguage(true);
  };

  const handleSaveNewLanguage = () => {
    API.technologiesRequest({
      method: "POST",
      data: {
        cm_technology: {
          name: newLanguage,
        },
      },
    })
      .then(() => {
        setNewLanguage("");
        setIsAddingLanguage(false);
        fetchLanguages();
      })
      .catch((error) => {
        console.error("API add new language request failed: ", error);
        alert("Failed to save new language");
      });
  };

  const handleCancelAddLanguage = () => {
    setIsAddingLanguage(false);
    setNewLanguage("");
  };

  const handleEditLanguage = (language) => {
    if (editingLanguage === null) {
      setEditingLanguage(language);
      setNewLanguage(language.language);
    }
  };

  const handleCancelEditLanguage = () => {
    setEditingLanguage(null);
    setNewLanguage("");
  };

  const handleSaveEditLanguage = () => {
    setIsSaving(true);
    API.technologiesRequest({
      method: "PATCH",
      path: {
        technology_id: editingLanguage.id,
      },
      data: {
        cm_technology: {
          name: newLanguage,
        },
      },
    })
      .then(() => {
        setNewLanguage("");
        setEditingLanguage(null);
        setIsSaving(false);
        fetchLanguages();
      })
      .catch((error) => {
        console.error("API language patch request failed: ", error);
        alert("Failed to save language change");
      });
  };

  return (
    <div className="modal">
      <Modal
        className="modal-edit-competency justify-content-center"
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="newLearningPointer Modal"
      >
        <div className="modal-content">
          <span className="close" onClick={closeModal}>
            &times;
          </span>
          <div className="modal-body">
            <h3>Manage Languages</h3>
            <div className="table-container">
              <table className="language-table">
                <thead>
                  <tr>
                    {columns.map((column, index) => (
                      <th key={column.accessor}>
                        {index === 1 ? "Edit" : column.Header}
                      </th>
                    ))}
                    <th>Edit</th>
                  </tr>
                </thead>
                <tbody>
                  {languages.map((language) => (
                    <tr key={language.language}>
                      <td>
                        {editingLanguage === language ? (
                          <input
                            type="text"
                            value={newLanguage}
                            onChange={(e) => setNewLanguage(e.target.value)}
                            placeholder="Enter language"
                          />
                        ) : (
                          language.language
                        )}
                      </td>
                      <td>
                        {editingLanguage === language ? (
                          <div>
                            <button
                              className="btn brand-button btn-sm"
                              disabled={!newLanguage || isSaving}
                              onClick={handleSaveEditLanguage}
                            >
                              Save
                            </button>
                            <button
                              className="btn brand-button btn-sm"
                              onClick={handleCancelEditLanguage}
                            >
                              Cancel
                            </button>
                          </div>
                        ) : (
                          <button
                            className="edit-button"
                            onClick={() => handleEditLanguage(language)}
                          >
                            <i className="fa fa-pencil"></i>
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {!isAddingLanguage ? (
              <button
                className="btn brand-button btn-sm"
                onClick={handleAddLanguage}
              >
                Add a Language
              </button>
            ) : (
              <div className="add-language">
                <input
                  type="text"
                  value={newLanguage}
                  onChange={(e) => setNewLanguage(e.target.value)}
                  placeholder="Enter language"
                />
                <button
                  className="btn brand-button btn-sm"
                  disabled={!newLanguage || isSaving}
                  onClick={handleSaveNewLanguage}
                >
                  Save
                </button>
                <button
                  className="btn brand-button btn-sm"
                  onClick={handleCancelAddLanguage}
                >
                  Cancel
                </button>
              </div>
            )}
            <div className="modal-footer">
              <button className="btn brand-button btn-sm" onClick={closeModal}>
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AdminManageLanguagePopup;
