import React, { useState } from "react";
import Modal from "react-modal";
import "../../stylesheets/CriteriaPopup.css";
import * as LEVELS from "../../constants/Levels";

Modal.setAppElement("#root");

const CriteriaPopup = ({ data }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const CRITERIA = [
    data.no_experience,
    data.basic,
    data.intermediate,
    data.advanced,
  ];
  const handleModalOpen = () => {
    document.body.classList.add("disable-scroll");
    setModalOpen(true);
  };

  const handleModalClose = () => {
    document.body.classList.remove("disable-scroll");
    setModalOpen(false);
  };

  return (
    <div>
      <button type="button" className="btn brand-button btn-sm" onClick={handleModalOpen}>View</button>
      <Modal
        className="modal-criteria"
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        contentLabel="Criteria Modal"
        style={{
          content: {
            margin: 'auto',
            maxHeight: '65vh',
            overflowY: 'auto',
            paddingLeft: '1%',
          },
        }}
      >
        <div className="row criteria-popup-title"><h2>Criteria for {data.name}</h2></div>
        <div className="criteria-levels">
          <ul>
            {LEVELS.COMPETENCY_LEVELS.map((level, index) => (
              <p key={index}>
                <strong>{level}</strong>: {CRITERIA[index]}
              </p>
            ))}
          </ul>
        </div>
        <div className="criteria-btns">
          <button className="criteria-btn btn company-theme" onClick={handleModalClose}>
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default CriteriaPopup;
