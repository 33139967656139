import React, { useState, useEffect } from "react";
import Select from "react-select";
import Modal from "react-modal";
import "../../stylesheets/NewLearningPointerPopup.css";
import API from "../../api/api";
import { useSearchParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { quillModules, quillFormats } from "../QuillEssentials";
import "../../stylesheets/wysiwyg.css";

const NewLearningPointerPopup = ({ isOpen, onClose, addLearningPointer }) => {
  const [searchParams] = useSearchParams();
  const competency_id = searchParams.get("competency_id");
  const [, setModalOpen] = useState(true);
  const [name, setName] = useState("");
  const [notes, setNotes] = useState("");
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [selectedCompetencies, setSelectedCompetencies] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);
  const [competencyOptions, setCompetencyOptions] = useState([]);
  const [allCompetencies, setAllCompetencies] = useState([]);

  const handleModalClose = () => {
    document.body.classList.remove("disable-scroll");
    onClose();
    setModalOpen(false);
  };

  useEffect(() => {
    API.areasRequest()
      .then((response) => {
        const newOptions = response.map((area) => ({
          value: area.id,
          label: area.name,
        }));
        setAreaOptions(newOptions);
      })
      .catch((error) => {
        console.error("areas request error: ", error);
        alert("Failed to get all areas");
      });

    API.competenciesRequest()
      .then((response) => {
        setAllCompetencies(
          response.map((competency) => ({
            value: competency.id,
            label: competency.name,
            area: competency.cm_area_id,
          }))
        );
      })
      .catch((error) => {
        console.error("competency request error: ", error);
        alert("Failed to get all competency");
      });
  }, []);

  useEffect(() => {
    if (competency_id && areaOptions.length > 0 && allCompetencies.length > 0) {
      const competencyOption = allCompetencies.find(
        (option) => option.value === parseInt(competency_id)
      );

      if (competencyOption) {
        setSelectedCompetencies([competencyOption]);
        setSelectedAreas([
          {
            value: competencyOption.area,
            label: areaOptions.find(
              (area) => area.value === competencyOption.area
            )?.label,
          },
        ]);
      }
    }
  }, [competency_id, areaOptions, allCompetencies]);

  useEffect(() => {
    if (selectedAreas.length > 0) {
      const newCompetencyOptions = allCompetencies.filter((comp) =>
        selectedAreas.some((area) => area.value === comp.area)
      );
      setCompetencyOptions(newCompetencyOptions);
    } else {
      setCompetencyOptions([]);
    }
  }, [allCompetencies, selectedAreas]);

  const handleAreaChange = (selectedAreas) => {
    setSelectedAreas(selectedAreas);
    let newSelectedCompetencies = selectedCompetencies.filter((competency) =>
      selectedAreas.some((area) => area.value === competency.area)
    );
    setSelectedCompetencies(newSelectedCompetencies);
  };

  const saveLearningPointer = () => {
    API.learningPointersRequest({
      method: "POST",
      data: {
        cm_learning_pointer: {
          name: name,
          description: notes,
        },
      },
    })
      .then((callback) => {
        selectedCompetencies.forEach((competency) => {
          API.competencyLearningPointersRequest({
            method: "POST",
            data: {
              cm_competency_learning_pointer: {
                cm_competency_id: competency.value,
                cm_learning_pointer_id: callback.id,
              },
            },
          }).catch((error) => {
            console.error(
              "competency learning pointer post request error: ",
              error
            );
            alert("Failed to create new competency learning pointer");
          });
        });
        addLearningPointer(callback);
      })
      .catch((error) => {
        console.error("learning pointer post request error: ", error);
        alert("Failed to create new learning pointer");
      });
  };

  return (
    <div>
      <Modal
        className="modal-new-learning-pointer"
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel="newLearningPointer Modal"
      >
        <h2 className="new-learning-pointer-popup-title">
          New Learning Pointer
        </h2>
        <label className="new-learning-pointer-popup-label">Name</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <label className="new-learning-pointer-popup-label">Notes</label>
        <ReactQuill
          className="quill-editor"
          value={notes}
          onChange={setNotes}
          modules={quillModules}
          formats={quillFormats}
        />
        <fieldset className="new-learning-pointer-field-set">
          <legend className="new-learning-pointer-field-set-legend">
            Tags
          </legend>
          <Select
            className="new-learning-pointer-dropdown"
            options={areaOptions}
            isMulti
            onChange={handleAreaChange}
            value={selectedAreas}
            placeholder="Select Area..."
          />
          {selectedAreas.length > 0 && (
            <Select
              className="new-learning-pointer-dropdown"
              options={competencyOptions}
              isMulti
              onChange={setSelectedCompetencies}
              value={selectedCompetencies}
              placeholder="Select Competency..."
            />
          )}
        </fieldset>
        <div className="new-learning-pointer-btn-container">
          <button
            className="new-learning-pointer-cancel-btn"
            onClick={handleModalClose}
          >
            Cancel
          </button>
          <button
            className="new-learning-pointer-save-btn"
            onClick={() => {
              saveLearningPointer();
              handleModalClose();
            }}
            disabled={!(name && notes)}
          >
            Save
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default NewLearningPointerPopup;
