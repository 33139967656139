import React, { useState, useEffect } from "react";
import "../../stylesheets/CompetencyTemplate.css";
import Modal from "react-modal";
import "../../App.css";
import API from "../../api/api";

const ManageRolesPopup = ({ closeModal, isOpen }) => {
  const [roles, setRoles] = useState([]);
  const [newRole, setNewRole] = useState("");
  const [isAddingRole, setIsAddingRole] = useState(false);
  const [editingRole, setEditingRole] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const columns = [
    {
      Header: "Disciplines",
      accessor: "role",
    },
  ];
  const fetchRoles = () => {
    API.rolesRequest()
      .then((response) => {
        const roles = response.map((role) => ({
          role: role.name,
          id: role.id,
        }));
        setRoles(roles);
      })
      .catch((error) => {
        console.error("API get roles request failed: ", error);
        alert("failed to retrive roles");
      });
  };
  useEffect(() => {
    fetchRoles();
  }, []);

  const handleAddRole = () => {
    setIsAddingRole(true);
  };

  const handleSaveNewRole = () => {
    API.rolesRequest({
      method: "POST",
      data: {
        cm_role: {
          name: newRole,
        },
      },
    })
      .then(() => {
        setNewRole("");
        setIsAddingRole(false);
        fetchRoles();
      })
      .catch((error) => {
        console.error("API new roles request error: ", error);
        alert("Failed to add new role");
      });
  };

  const handelCancelAddRole = () => {
    setIsAddingRole(false);
    setNewRole("");
  };

  const handleEditRole = (role) => {
    if (editingRole === null) {
      setEditingRole(role);
      setNewRole(role.role);
    }
  };

  const handleCancelEditRole = () => {
    setEditingRole(null);
    setNewRole("");
  };

  const handleSaveEditRole = () => {
    setIsSaving(true);
    API.rolesRequest({
      method: "PATCH",
      path: {
        role_id: editingRole.id,
      },
      data: {
        cm_role: {
          name: newRole,
        },
      },
    })
      .then(() => {
        setNewRole("");
        setEditingRole(null);
        setIsSaving(false);
        fetchRoles();
      })
      .catch((error) => {
        console.error("failed to patch role request: ", error);
        alert("Failed to edit role");
      });
  };

  return (
    <div className="modal">
      <Modal
        className="modal-edit-competency justify-content-center"
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="newLearningPointer Modal"
      >
        <div className="modal-content">
          <span className="close" onClick={closeModal}>
            &times;
          </span>
          <div className="modal-body">
            <h3>Manage Disciplines</h3>
            <div className="table-container">
              <table className="language-table">
                <thead>
                  <tr>
                    {columns.map((column, index) => (
                      <th key={column.accessor}>
                        {index === 1 ? "Edit" : column.Header}
                      </th>
                    ))}
                    <th>Edit</th>
                  </tr>
                </thead>
                <tbody>
                  {roles.map((role) => (
                    <tr key={role.role}>
                      <td>
                        {editingRole === role ? (
                          <input
                            type="text"
                            value={newRole}
                            onChange={(e) => setNewRole(e.target.value)}
                            placeholder="Enter role"
                          />
                        ) : (
                          role.role
                        )}
                      </td>
                      <td>
                        {editingRole === role ? (
                          <div>
                            <button
                              className="btn brand-button btn-sm"
                              disabled={!newRole || isSaving}
                              onClick={handleSaveEditRole}
                            >
                              Save
                            </button>
                            <button
                              className="btn brand-button btn-sm"
                              onClick={handleCancelEditRole}
                            >
                              Cancel
                            </button>
                          </div>
                        ) : (
                          <button
                            className="edit-button"
                            onClick={() => handleEditRole(role)}
                          >
                            <i className="fa fa-pencil"></i>
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {!isAddingRole ? (
              <button
                className="btn brand-button btn-sm"
                onClick={handleAddRole}
              >
                Add a Discipline
              </button>
            ) : (
              <div className="add-language">
                <input
                  type="text"
                  value={newRole}
                  onChange={(e) => setNewRole(e.target.value)}
                  placeholder="Enter role"
                />
                <button
                  className="btn brand-button btn-sm"
                  disabled={!newRole || isSaving}
                  onClick={handleSaveNewRole}
                >
                  Save
                </button>
                <button
                  className="btn brand-button btn-sm"
                  onClick={handelCancelAddRole}
                >
                  Cancel
                </button>
              </div>
            )}
            <div className="modal-footer">
              <button className="btn brand-button btn-sm" onClick={closeModal}>
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default ManageRolesPopup;
