import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useTable, useSortBy, useFilters } from "react-table";
import { getColumns } from "../constants/CompetencySheetColumn";
import "../stylesheets/CompetencySheetTable.css";
import API from "../api/api";
import { useContext } from "react";
import { UserContext } from "../contexts/UserContext";
import { SheetUserContext } from "../contexts/SheetUserContext";

const CompetencySheetTable = ({ filterRole }) => {
  const columns = useMemo(() => getColumns(filterRole), [filterRole]);
  const [data, setData] = useState([]);
  const { user } = useContext(UserContext);
  const { sheetUser } = useContext(SheetUserContext);
  const defaultColumn = useMemo(
    () => ({
      Filter: () => null,
    }),
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useFilters,
    useSortBy
  );

  useEffect(() => {
    const current_sheet_id =
      sheetUser && sheetUser.sheet_id != null
        ? sheetUser.sheet_id
        : user.sheet_id;
    if (current_sheet_id) {
      API.sheetsRequest({ path: { sheet_id: current_sheet_id } }).then(
        (sheetResponse) => {
          const data = sheetResponse.sheet_competencies;
          data.sort((a, b) => a.area_name.localeCompare(b.area_name));
          if (filterRole && filterRole.value) {
            const roleFilteredData = data.filter(
              (row) => row.competency.cm_role_id === filterRole.value
            );
            setData(roleFilteredData);
          } else {
            setData(data);
          }
        }
      );
    }
  }, [sheetUser, user, filterRole]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;
  return (
    <div>
      <br />
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th className="th-sticky" {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? " ↓" : " ↑") : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  if (cell.column.id === "competency_change.updated_at") {
                    return (
                      <td {...cell.getCellProps()}>{formatDate(cell.value)}</td>
                    );
                  } else {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

function formatDate(dateString) {
  const options = {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  const date = new Date(dateString);
  return date.toLocaleString("en-US", options);
}

export default CompetencySheetTable;
