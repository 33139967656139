import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom'
import API from "../api/api";
import Select from "react-select";
import "../stylesheets/Competency.css";
import { create } from "@mui/material/styles/createTransitions";

const Competency = ({}) => {
  const navigate = useNavigate();

  const competencyID = useParams().competency_id;
  const [competency, setCompetency] = useState();

  const [selectedArea, setSelectedArea] = useState({});
  const [areaOptions, setAreaOptions] = useState([]);
  const [selectedRole, setSelectedRole] = useState({});
  const [roleOptions, setRoleOptions] = useState([]);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [noExperience, setNoExperience] = useState("");
  const [basic, setBasic] = useState("");
  const [intermediate, setIntermediate] = useState("");
  const [advanced, setAdvanced] = useState("");
  const [published, setPublished] = useState();
  const [isFormValid, setIsFormValid] = useState(false);


  //Used in initial rendering, Grabs data needed for form that is used in dropdowns etc...
  useEffect(()  => {
    if(competencyID != null){
      API.competenciesRequest({path: {competency_id: competencyID}}).then((competency) => {
        setCompetency(competency);
        prepAreaDropdown(competency);
        prepRoleDropdown(competency);
  
        setName(competency.name);
        setDescription(competency.description);
        setNoExperience(competency.no_experience);
        setBasic(competency.basic);
        setIntermediate(competency.intermediate);
        setAdvanced(competency.advanced);
        setPublished(competency.published)
      })
    }
    else{
      prepAreaDropdown(null);
      prepRoleDropdown(null);
    }
  }, [])

  //Checks if form is valid, is ran when required form elements are changed. Prevents users from submitting
  useEffect(() => {
    let isValid =
      name !== "" &&
      description !== "" &&
      Object.keys(selectedArea).length !== 0 &&
      Object.keys(selectedRole).length !== 0 &&
      noExperience !== "" &&
      basic !== "" &&
      intermediate !== "" &&
      advanced !== "";
    setIsFormValid(isValid);
  }, [ name, description, selectedArea, selectedRole, noExperience, basic, intermediate, advanced ]);


  //Grabs the data needed to set area dropdown. 
  //Determines if editing existing competency. Sets SelectedArea
  function prepAreaDropdown(competency){
    API.areasRequest().then((response) => {
      const newOptions = response.map((area) => ({
        value: area.id,
        label: area.name,
      }));
      const areaIdtoName = new Map();
      response.forEach((area) => {
        areaIdtoName.set(area.id, area.name);
      });
      setAreaOptions(newOptions);
      if(competency != null){
        setSelectedArea({
          value: competency.cm_area_id,
          label: areaIdtoName.get(competency.cm_area_id),
        });
      } 
    }).catch((error) => {
      console.error("API areas request error: ", error);
      alert("Failed to retrieve Areas");
    }); 
  }

  //Grabs the data needed to set role dropdown. 
  //Determines if editing existing competency. Sets SelectedRoles
  function prepRoleDropdown(competency){
    API.rolesRequest().then((response) => {
      const roleOptions = response.map((role) => ({
        value: role.id,
        label: role.name,
      }));
      const roleIdtoName = new Map();
      response.forEach((area) => {
        roleIdtoName.set(area.id, area.name);
      });
      setRoleOptions(roleOptions);
      if(competency != null){
        setSelectedRole({
          value: competency.cm_role_id,
          label: roleIdtoName.get(competency.cm_role_id),
        });
      }
    }).catch((error) => {
      console.error("API roles request error: ", error);
      alert("Failed to retrieve roles");
    });
  }

  //Send request to update competency
  function updateCompetency(){
    let currentDate = new Date().toISOString();
    API.competenciesRequest({
      method: "PUT",
      path: {
        competency_id: competencyID
      },
      data: {
        cm_competency: {
          name: name,
          description: description,
          cm_area_id: selectedArea.value,
          cm_role_id: selectedRole.value,
          no_experience: noExperience,
          basic: basic,
          intermediate: intermediate,
          advanced: advanced,
          updated_at: currentDate,
          published: published,
        },
      }
    }).then((response) => {
      //Add any functionality once competency has been saved
    }).catch((error) => {
      console.error("API Competency Update Request Failure: ", error);
      alert('Failed to update Competency');
    })
  };

  //Sends request to create Competency
  function createCompetency(){
    const currentDate = new Date().toISOString();
    API.competenciesRequest({
      method: "POST",
      data: {
        cm_competency: {
          name: name,
          description: description,
          cm_area_id: selectedArea.value,
          cm_role_id: selectedRole.value,
          no_experience: noExperience,
          basic: basic,
          intermediate: intermediate,
          advanced: advanced,
          published: published,
        },
      },
    }).then((response) => {
      //functionality once competency created
    }).catch((error) => {
      console.error("competency request post error: ", error);
      alert("Failed to add new competency");
    });
  }

  //Handles form submission
  function handleFormSubmission(){
    competencyID != null ? updateCompetency() : createCompetency();
    localStorage.setItem('notification', 'Changes Saved');
    navigate('/competency-matrix-template')
  }

  return (
    <div className="container form-container">
      <div className="text-center">
        <h1>{(!!competencyID) ? name : 'New Competency'}</h1>
      </div>
      <br/>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <label className="form-label">Name:</label>
          <input
            className="form-control"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
      </div><br />
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <label className="form-label">Description:</label>
          <textarea
            className="form-control"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
      </div><br /> 
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <label className="form-label">Area:</label>
          <Select
            className="form-dropdown"
            options={areaOptions}
            onChange={(event) => setSelectedArea(event)}
            value={selectedArea}
            placeholder="Select Area..."
          />
        </div>
      </div><br />      
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <label className="form-label">Discipline:</label>
          <Select
            className="form-dropdown"
            options={roleOptions}
            onChange={(event) => setSelectedRole(event)}
            value={selectedRole}
            placeholder="Select Role..."
          />
        </div>
      </div> <br /><br />
      <div className="row">
        <br /><br /><hr />
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <div className="col-md-12">
            <h3 className="fw-bold">Criteria</h3>
          </div>
        </div><br /><br /><br />
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-10">
            <label className="form-label">No Experience:</label>
            <textarea
              className="form-textarea"
              type="text"
              value={noExperience}
              onChange={(e) => setNoExperience(e.target.value)}
            />
          </div>
        </div><br />
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-10">
            <label className="form-label">Basic:</label>
            <textarea
              className="form-textarea"
              type="text"
              value={basic}
              onChange={(e) => setBasic(e.target.value)}
            />
          </div>
        </div><br />
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-10">
            <label className="form-label">Intermediate:</label>
            <textarea
              className="form-textarea"
              type="text"
              value={intermediate}
              onChange={(e) => setIntermediate(e.target.value)}
            />
          </div>
        </div><br />
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-10">
            <label className="form-label">Advanced:</label>
            <textarea
              className="form-textarea"
              type="text"
              value={advanced}
              onChange={(e) => setAdvanced(e.target.value)}
            />
          </div>
        </div><br />
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-10 form-switch">
            <input className="form-check-input" 
              type="checkbox"
              checked = {published}
              onChange={ (event) => {setPublished(event.target.checked)} } 
            />
            <label className="form-check-label" style={{marginLeft: '1%'}}> Publish</label>
          </div>
        </div>
      </div>
      <br />
      <div className="text-center">
        <button type="button" className="btn brand-button btn-sm" onClick={() => navigate('/competency-matrix-template')}>
          Cancel
        </button>
        <button
          className="btn brand-button btn-sm"
          onClick={() => { handleFormSubmission()}}
          disabled={!isFormValid}
        >
          Save
        </button>
      </div>   
      <br />
    </div>
  )
};

export default Competency