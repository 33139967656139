import React, { useContext, useState } from "react";
import Modal from "react-modal";
import "../../stylesheets/NotesPopup.css";
import API from "../../api/api";
import { UserContext } from "../../contexts/UserContext";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { quillModules, quillFormats } from "../QuillEssentials";
import "../../stylesheets/wysiwyg.css";

Modal.setAppElement("#root");

const NotesPopup = ({ data }) => {
  const { user } = useContext(UserContext);
  const [isModalOpen, setModalOpen] = useState(false);
  const [notes, setNotes] = useState(data.sheet_competency.notes || "");
  const handleModalOpen = () => {
    document.body.classList.add("disable-scroll");
    setModalOpen(true);
  };

  const handleModalClose = () => {
    handleSaveNotes();
    document.body.classList.remove("disable-scroll");
    setModalOpen(false);
  };

  const handleSaveNotes = () => {
    API.sheetCompetenciesRequest({
      method: "patch",
      path: {
        sheet_id: user.sheet_id,
        sheet_competency_id: data.sheet_competency.id,
      },
      data: {
        cm_sheet_competency: {
          notes: notes,
        },
      },
    }).catch((error) => {
      console.error("sheet competency path request error: ", error);
      alert("failed to have change to notes");
    });
  };
  return (
    <div>
      <button onClick={handleModalOpen}>
        <i className="fa fa-pencil"></i>
      </button>
      <Modal
        className="modal-notes"
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        contentLabel="Notes Modal"
      >
        <h2 className="notes-popup-title">Notes for {data.competency.name}</h2>
        <ReactQuill
          className="notes-quill-editor"
          value={notes}
          onChange={setNotes}
          modules={quillModules}
          formats={quillFormats}
        />
        <div className="notes-btns">
          <button className="notes-btn" onClick={handleModalClose}>
            Save
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default NotesPopup;
