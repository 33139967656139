import "./App.css";
import React, { useState, useEffect, useContext } from "react";
import Login from "./pages/Login";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import Sheet from "./pages/Sheet";
import LearningPointers from "./pages/LearningPointers";
import CompetenciesTemplate from "./pages/CompetenciesTemplate";
import ManagerPortal from "./pages/ManagerPortal";
import Competency from "./pages/Competency";
import API from "./api/api";
import { UserContext } from "./contexts/UserContext";
import LoadingSpinner from "./uiElements/LoadingSpinner";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const { user, setAdmin, setManager } = useContext(UserContext);
  const navigate = useNavigate();
  const { setUserViaResponse } = useContext(UserContext);
  useEffect(() => {
    const getCookie = (name) => {
      const value = "; " + document.cookie;
      const parts = value.split("; " + name + "=");
      if (parts.length === 2) return parts.pop().split(";").shift();
    }
    const verifyUser = async (token) => {
      const params = { current_user: true };
      if (!token) {
        setIsLoading(false);
        return
      }
      try {
        const response = await API.usersRequest({ params });
        if (response) {
          setUserViaResponse(response);
          setAdmin(response.is_admin);
          setManager(response.is_manager);
          navigate("/sheet");
        }
      } catch (error) {
        console.error("API usersRequest error:", error);
      } finally {
        setIsLoading(false);
      }
    };

    setIsLoading(true);
    let cm_token = getCookie("transition_token");
    if (cm_token) {
      // Store token in localStorage and remove from cookies
      localStorage.setItem("cm_token", cm_token);
      document.cookie = `transition_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${process.env.REACT_APP_CM_COOKIE_DOMAIN}`;
    } else {
      cm_token = localStorage.getItem("cm_token")
    }
    if (cm_token) {
      verifyUser(cm_token);
    } else {
      setIsLoading(false);
    }
  }, []);

  if (!isLoading && user.is_admin === null) {
    user.is_admin = false;
  }
  return (
    <React.Fragment>
      <div className="App">
        <div className="container">
          <Header />
        </div>
        <div className="pages">
          {user === null ? (
            <Navigate to="/" />
          ) : isLoading || user.is_admin === null ? (
            <LoadingSpinner />
          ) : (
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/sheet" element={<Sheet />} />
              <Route
                path="/users/:user_id/sheets/:sheet_id/"
                element={
                  user.is_admin || user.is_manager ? (
                    <Sheet key={`sheet_user`} />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route
                path="/learning-pointers/"
                element={<LearningPointers />}
              />
              <Route
                path="/competency-matrix-template"
                element={
                  (user.is_admin || user.is_manager) ? (
                    <CompetenciesTemplate />
                  ) : (
                    <Navigate to="/sheet" />
                  )
                }
              />
              <Route
                path="/competency-matrices"
                element={
                  user.is_admin || user.is_manager ? (
                    <ManagerPortal />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route
                path='/competency/:competency_id?'
                element={<Competency />}
              />
            </Routes>
          )}
        </div>
        <div className="container">
          <Footer />
        </div>
      </div>
    </React.Fragment>
  );
}

export default App;
