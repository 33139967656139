import React from "react";
import "../stylesheets/LevelDescription.css"

export const LevelDescription = () => {
  return (
    <div className="level-description-table">
      <table>
        <thead>
          <tr>
            <th>Level</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>None</td>
            <td>
              No experience with this competency or awaiting manager approval
            </td>
          </tr>
          <tr>
            <td>Basic</td>
            <td>
              Has the basics of this concept down <br /> Should be able to do
              simple things by themselves, may require some assistance
            </td>
          </tr>
          <tr>
            <td>Intermediate</td>
            <td>
              Has most common items in this concept down <br /> Should be able
              to do most things by themselves, only pointers required for help
            </td>
          </tr>
          <tr>
            <td>Advanced</td>
            <td>
              Knows this concept well and can do all items on their own <br />{" "}
              Occasional use of google for refresh is ok<br/>
              At least 6 months working with this item
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default LevelDescription;
