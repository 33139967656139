import CompetencySheetTable from "../components/CompetencySheetTable";
import React, { useContext, useEffect, useState } from "react";
import { LevelDescription } from "../constants/LevelDescription";
import "../App.css";
import ManageLanguagePopup from "../components/modal/ManageLanguagePopup";
import "../Sheet.css";
import { UserContext } from "../contexts/UserContext";
import { useLocation, useParams } from "react-router-dom";
import API from "../api/api";
import { SheetUserContext } from "../contexts/SheetUserContext";
import EditRolesPopup from "../components/modal/EditRolesPopup";
import LanguageSelectPopup from "../components/modal/LanguageSelectPopup";

const Sheet = () => {
  const { user_id, sheet_id } = useParams();
  const { user } = useContext(UserContext);
  const [roles, setRoles] = useState("");
  const [languages, setLanguages] = useState(null);
  const { sheetUser, setSheetUser, setSheetUserViaResponse } =
    useContext(SheetUserContext);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const [filterRole, setFilterRole] = useState({});
  const [roleNameToId, setRoleNameToId] = useState(new Map());

  useEffect(() => {
    const fetchData = async (userId, sheetId) => {
      try {
        const languagesResponse = await API.userTechnologiesRequest({
          path: {
            sheet_id: sheetId,
          },
          params: {
            user_id: userId,
          },
        });
        const languagesList = languagesResponse
          .map((language) => language.name)
          .join(", ");
        setLanguages(languagesList);

        const rolesResponse = await API.sheetRolesRequest({
          params: {
            user_id: userId,
          },
        });
        const rolesList = rolesResponse.map((role) => role.name).join(", ");
        setRoles(rolesList);
        const namesToIds = new Map();
        rolesResponse.forEach((role) => {
          namesToIds.set(role.name, role.id);
        });
        setRoleNameToId(namesToIds);
      } catch (error) {
        console.error("Error fetching roles and language data: ", error);
        alert("Failed to fetch roles and language data");
      }
      setFilterRole({});
    };
    if (!isLoading) {
      if (sheetUser && sheetUser.sheet_user_id != null && sheetUser.sheet_id != null) {
        fetchData(sheetUser.sheet_user_id, sheetUser.sheet_id);
      } else {
        fetchData(user.user_id, user.sheet_id);
      }
    }
  }, [isLoading, sheetUser, user]);

  useEffect(() => {
    setIsLoading(true);
    if (sheet_id && user_id) {
      API.usersRequest({
        path: {
          user_id: user_id,
        },
      }).then((response) => {
        setSheetUserViaResponse(response);
        setIsLoading(false);
      });
    } else {
      setSheetUser(null);
      setIsLoading(false);
    }
  }, [location, sheet_id, user_id]);

  const full_name = sheetUser && sheet_id ? `${sheetUser.first_name} ${sheetUser.last_name}` : `${user.first_name} ${user.last_name}`
  const title = !isLoading && `${full_name}'s Competency Matrix`;

  const handleFilterChange = (currentRole) => {
    setFilterRole({
      label: currentRole,
      value: roleNameToId.get(currentRole),
    });
  };
  
  return (
    <div className="container">
      <div className="d-flex">
        <div className="flex-fill text-center">
          <h2>{title}</h2>
          <h4>Disciplines: {roles}</h4>
          {roles.includes("Developer") && <h4>Languages: {languages}</h4>}
          <br />
          <div className="d-flex justify-content-center">
            {(user.is_admin || user.is_manager) && sheetUser != null && <EditRolesPopup />}
            {roles.includes("Developer") && <ManageLanguagePopup />}
          </div>
          <br />
          <div className="filter-container">
            <p className="filter-by-discipline">Filter by Discipline</p>
            <select
              className="pt-0 pl-3"
              value={filterRole.label}
              onChange={(e) => handleFilterChange(e.target.value)}
            >
              <option value="">All</option>
              {roles.split(", ").map((role) => (
                <option key={role} value={role}>
                  {role}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="flex-fill text-center">
          <LevelDescription />
        </div>
      </div>
      {!isLoading && roles.includes("Developer") && languages !== null && languages === "" && (<LanguageSelectPopup />)}
      <div>
        {!isLoading && <CompetencySheetTable filterRole={filterRole} />}
      </div>
    </div>
  );
};

export default Sheet;
