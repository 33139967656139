import ManagerTable from "../components/ManagerTable";
import React from "react";

const ManagerPortal = () => {
  return (
    <div className="text-center">
      <h1>Competency Matrices</h1>
      <ManagerTable />
    </div>
  );
};

export default ManagerPortal;
