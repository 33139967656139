import React, { useContext, useEffect, useState } from "react";
import { useTable } from "react-table";
import { useSearchParams, useNavigate } from "react-router-dom";
import API from "../api/api";
import NewLearningPointerPopup from "../components/modal/NewLearningPointerPopup";
import { UserContext } from "../contexts/UserContext";
import EditLearningPointerPopup from "../components/modal/EditLearningPointerPopup";
import DOMPurify from "dompurify";

const LearningPointers = () => {
  const [data, setData] = useState([]);
  const [competency_name, setCompetencyName] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const { user } = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const competency_id = searchParams.get("competency_id");
  const navigate = useNavigate();
  const [selectedLearningPointer, setSelectedLearningPointer] = useState(null);
  const [showEditPopup, setShowEditPopup] = useState(false);

  const columns = React.useMemo(
    () => [
      { Header: "Learning Pointer", accessor: "name" },
      {
        Header: "Description",
        accessor: "description",
        Cell: ({ value }) => <div className="wrap-text" dangerouslySetInnerHTML={{ __html: processHTMLContent(DOMPurify.sanitize(value)) }} />,
      },
    ],
    []
  );

  const processHTMLContent = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
    const anchorTags = doc.querySelectorAll("a");
    anchorTags.forEach((anchor) => {
      anchor.setAttribute("target", "_blank");
    });
    return doc.body.innerHTML;
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  useEffect(() => {
    API.learningPointersRequest({
      params: { competency_id: competency_id },
    })
      .then((responseCompetencyLearningPointers) => {
        setData(
          responseCompetencyLearningPointers.map((pointer) => ({
            ...pointer,
            description: pointer.description,
            url: pointer.url,
          }))
        );
      })
      .catch((error) => {
        console.error(
          "competency learning pointer request error: ",
          error
        );
        alert("Failed to retrieve all competency learning pointers");
      });
    const names = new Map();
    API.competenciesRequest()
      .then((response) => {
        response.forEach((item) => {
          names.set(item.id, item.name);
        });
        const name = names.get(parseInt(competency_id));
        setCompetencyName(name);
      })
      .catch((error) => {
        console.error("competency request error: ", error);
        alert("failed to retrieve all competencies");
      });
  }, [user.sheet_id, showPopup, competency_id, user.user_id, competency_name]);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleModalOpen = () => {
    setShowPopup(true);
  };

  const addLearningPointer = (learningPointer) => {
    setData((prevData) => [...prevData, learningPointer]);
  };

  const handleLearningPointerClick = (learningPointer) => {
    if (user.is_admin) {
      setSelectedLearningPointer(learningPointer);
      setShowEditPopup(true);
    }
  };

  const handleSaveEditLearningPointer = (updatedCompetency) => {
    setData((prevData) => {
      const newData = prevData.map((competency) => {
        if (competency.competency_id === updatedCompetency.competency_id) {
          return updatedCompetency;
        }
        return competency;
      });
      return newData;
    });
  };

  return (
    <div className="container justify-content-center">
      <div className="text-center">
        <h1>Learning Pointers for {competency_name}</h1>
        <br />
        <button className="btn brand-button" onClick={handleModalOpen}>
          Add Learning Pointer
        </button>
      </div>
      <br />
      <table {...getTableProps()} className=" table-striped">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  if (cell.column.id === "name") {
                    return (
                      <td {...cell.getCellProps() } className="wrap-text">
                        {user.is_admin ? (
                          <a
                            href="#"
                            onClick={() =>
                              handleLearningPointerClick(row.original)
                            }
                          >
                            {cell.render("Cell")}
                          </a>
                        ) : (
                          cell.render("Cell")
                        )}
                      </td>
                    );
                  } else {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <br />
      <button className="btn brand-button back-button" onClick={() => navigate(-1)}>Back</button>
      {showPopup && (
        <NewLearningPointerPopup
          isOpen={showPopup}
          onClose={handleClosePopup}
          handleModalOpen={handleModalOpen}
          addLearningPointer={addLearningPointer}
        />
      )}
      {selectedLearningPointer && (
        <EditLearningPointerPopup
          learningPointer={selectedLearningPointer}
          isOpen={showEditPopup}
          onClose={() => setShowEditPopup(false)}
          onSaveLearningPointer={handleSaveEditLearningPointer}
        ></EditLearningPointerPopup>
      )}
    </div>
  );
};

export default LearningPointers;
