import React, { useState } from "react";
import Modal from "react-modal";
import "../../stylesheets/EditRolesPopup.css";
import EditRolesTable from "./EditRolesTable";

Modal.setAppElement("#root");

const EditRolesPopup = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => {
    document.body.classList.add("disable-scroll");
    setModalOpen(true);
  };

  const handleModalClose = () => {
    document.body.classList.remove("disable-scroll");
    setModalOpen(false);
  };

  return (
    <div>
      <button
        type="button"
        className="btn brand-button btn-sm"
        onClick={handleModalOpen}
      >
        Edit Disciplines
      </button>
      <Modal
        className="modal-edit-roles"
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        contentLabel="Edit Roles Modal"
      >
        <h2>Edit Disciplines</h2>
        <EditRolesTable
          className="edit-roles-table"
          onClose={handleModalClose}
        />
      </Modal>
    </div>
  );
};

export default EditRolesPopup;
