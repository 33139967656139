import React, { useState } from "react";
import Modal from "react-modal";
import ManageLanguagesTable from "./ManageLanguagesTable";
import "../../stylesheets/ManageLanguagePopup.css";

Modal.setAppElement("#root");

const ManageLanguagePopup = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => {
    document.body.classList.add("disable-scroll");
    setModalOpen(true);
  };

  const handleModalClose = () => {
    document.body.classList.remove("disable-scroll");
    setModalOpen(false);
  };

  return (
    <div>
      <button
        type="button"
        className="btn brand-button btn-sm"
        onClick={handleModalOpen}
      >
        Manage Languages
      </button>
      <Modal
        className="modal-manage-languages"
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        contentLabel="Manage Languages Modal"
      >
        <h2>Manage Languages</h2>
        <ManageLanguagesTable
          className="manage-languages-table"
          onClose={handleModalClose}
        />
      </Modal>
    </div>
  );
};

export default ManageLanguagePopup;
